import {FormFieldBase, FormFieldBaseOptions} from './form-field-base';
import {Observable} from 'rxjs';
import {CompareWithFn} from '@ng-select/ng-select/lib/ng-select.component';

export interface OptionsAPIOptions {
    viewSet: any;
    listMethod?: string;
    retrieveMethod?: string;
    id?: string;
    name?: any;
    nameFunction?: (item) => string;
    valueFunction?: (item) => any;
    filtersFunction?: (contextData) => any;
    processSelectItem?: (item) => any;
}

export interface FormFieldSelectOptions<T = any> extends FormFieldBaseOptions<T> {
    options?: FormFieldOption[];
    optionsAPI?: OptionsAPIOptions;
    multi?: boolean;
    optionsObs?: Observable<FormFieldOption[]>;
    asyncOptionsAPI?: OptionsAPIOptions;
    addItemText?: string;
    addItemCallback?: (name: string) => any;
    labelProp?: string;
    valueProp?: string;
    sameAsProp?: string;
    bindWholeValue?: boolean;
    virtualScroll?: boolean;
    compareWith?: CompareWithFn;
    searchFn?: (term: string, item: any) => boolean;
}

export interface FormFieldOption {
    id: string | number;
    name: string | number;
}

export class FormFieldSelect<T = any> extends FormFieldBase<T> {
    controlType = 'select';
    options: FormFieldOption[] = [];
    optionsAPI?: OptionsAPIOptions;
    multi?: boolean = false;
    asyncOptionsAPI?: OptionsAPIOptions;
    addItemText: string;
    addItemCallback: (name: string) => any;
    labelProp = 'name';
    valueProp = 'id';
    sameAsProp? = 'same_as';
    bindWholeValue = false;
    virtualScroll?: boolean;

    constructor(options: FormFieldSelectOptions<T> = {}) {
        super(options);
        if (options.optionsAPI) this.optionsAPI = options.optionsAPI;
        if (options.options) this.options = options.options;
        if (options.multi) this.multi = true;
        if (options.optionsObs) {
            const obs = options.optionsObs;
            obs.subscribe(obs => {
                this.options = obs;
            });
        }
        if (options.asyncOptionsAPI) this.asyncOptionsAPI = options.asyncOptionsAPI;
        if (options.addItemCallback) this.addItemCallback = options.addItemCallback;
        if (options.addItemText) this.addItemText = options.addItemText;
        if (options.labelProp) this.labelProp = options.labelProp;
        if (options.sameAsProp) this.sameAsProp = options.sameAsProp;
        if (options.bindWholeValue) {
            this.bindWholeValue = options.bindWholeValue;
            this.valueProp = null;
        }
        if (options.valueProp && !this.bindWholeValue) this.valueProp = options.valueProp;
        if (options.searchTermMinLength) this.searchTermMinLength = options.searchTermMinLength;
        if (options.virtualScroll) this.virtualScroll = options.virtualScroll;
        if (options.compareWith) this.compareWith = options.compareWith;
        if (options.searchFn) this.searchFn = options.searchFn;
    }
}
